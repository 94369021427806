import React from "react";
import { NavLink } from "react-router-dom";
import WaitList from "../../components/wait-list/index";

const NavBar = ({ handleMobileNavDisplay, sticky }) => {
  return (
    <header className={`main-header-four ${sticky}`}>
      <nav className="main-menu main-menu-four">
        <div className="main-menu-four__wrapper">
          <div className="main-menu-four__wrapper-inner">
            <div className="main-menu-four__left">
              <div className="main-menu-four__logo">
                <a href="index.html">
                  <img
                    src="assets/images/update-10-02-2023/resources/c491f5b1-44a3-4d51-b07a-ab02bb2bda1b-removebg-preview.png"
                    alt=""
                  />
                </a>
              </div>
            </div>
            <div className="main-menu-four__right">
              <div className="main-menu-four__top">
                <ul className="list-unstyled main-menu-four__top-address-list">
                  <li>
                    <i className="icon">
                      <span className="icon-email"></span>
                    </i>
                    <div className="text">
                      <p>
                        <a
                          href="mailto: nextgenhealthtechltd@gmail.com "
                          style={{ color: "white" }}
                        >
                          nextgenhealthtechltd@gmail.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <i className="icon">
                      <span className="icon-pin"></span>
                    </i>
                    <div className="text">
                      <p>online</p>
                    </div>
                  </li>
                </ul>
                <div className="main-menu-four__top-social-btn">
                  <div className="main-menu-four__top-social">
                    <a href="https://twitter.com/NextGenHealthNG">
                      <i className="fab fa-twitter"></i>
                    </a>
                    <a href="#">
                      <i className="fab fa-facebook"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/nextgen-health-tech-ltd/">
                      <i className="fab fa-linkedin-in"></i>
                    </a>
                    <a href="https://instagram.com/nextgenhealthtech?igshid=NzZlODBkYWE4Ng==">
                      <i className="fab fa-instagram"></i>
                    </a>
                  </div>
                  <div className="main-menu-four__btn-box">
                    <a
                      href="#insurance"
                      className="thm-btn-two main-menu-four__btn"
                    >
                      Get a Quote
                    </a>
                  </div>
                </div>
              </div>
              <div className="main-menu-four__bottom">
                <div className="main-menu-four__main-menu-box">
                  <a
                    href="#"
                    className="mobile-nav__toggler"
                    onClick={handleMobileNavDisplay}
                  >
                    <i class="fa fa-bars"></i>
                  </a>
                  <ul className="main-menu__list one-page-scroll-menu">
                    <li className="dropdown current scrollToLink">
                      <a href="#home">Home </a>
                    </li>
                    <li className="scrollToLink">
                      <a href="#about">About</a>
                    </li>
                    <li className="scrollToLink">
                      <a href="#services">Services</a>
                    </li>
                    {/* <li className="scrollToLink">
                      <a href="#team">Team</a>
                    </li> */}
                    <li className="scrollToLink">
                      <a href="#insurance">Join WaitList </a>
                    </li>
                  </ul>
                </div>
                <div className="main-menu-four__call-search-cart">
                  <div className="main-menu-four__call">
                    <div className="main-menu-four__call-icon">
                      <i className="fas fa-phone"></i>
                    </div>
                    <div className="main-menu-four__call-content">
                      <p>Call Anytime</p>
                      <a href="tel:8898006802">+ 234 810 373 4621</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavBar;

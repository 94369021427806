import React from "react";

const MainSlider = () => {
  return (
    <section className="main-slider-four clearfix" id="home">
      <div
        className="swiper-container thm-swiper__slider"
        data-swiper-options='{"slidesPerView": 1, "loop": true,
        "effect": "fade",
        "pagination": {
        "el": "#main-slider-pagination",
        "type": "bullets",
        "clickable": true
        },
        "navigation": {
        "nextEl": "#main-slider__swiper-button-next",
        "prevEl": "#main-slider__swiper-button-prev"
        },
        "autoPlay": {
        "delay": 5000
        }}'
      >
        <div className="swiper-wrapper">
          <div className="swiper-slide">
            <div className="main-slider-four__img">
              <img
                src="/assets/images/update-10-02-2023/resources/main-slider-four-img-1.jpg"
                alt=""
              />
            </div>
            {/* <!-- /.image-layer --> */}

            <div className="main-slider-four__shape-1 float-bob-y">
              <img
                src="/assets/images/update-10-02-2023/shapes/main-slider-four-shape-1.png"
                alt=""
              />
            </div>
            <div className="main-slider-four__shape-2 float-bob-x">
              <img
                src="/assets/images/update-10-02-2023/shapes/main-slider-four-shape-2.png"
                alt=""
              />
            </div>
            <div className="main-slider-four__shadow"></div>

            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="main-slider-four__content">
                    <p className="main-slider-four__sub-title">
                      Your Path to Wellness Starts Here
                    </p>
                    <h2 className="main-slider-four__title">
                      Affordable <br /> Health Insurance <br /> Solutions
                      <span>.</span>
                    </h2>
                    <div className="main-slider-four__btn-box">
                      <a
                        href="#insurance"
                        className="thm-btn-two main-slider-four__btn"
                      >
                        Discover More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="main-slider-four__img">
              <img
                src="/assets/images/update-10-02-2023/resources/main-slider-four-img-1.jpg"
                alt=""
              />
            </div>
            {/* <!-- /.image-layer --> */}

            <div className="main-slider-four__shape-1 float-bob-y">
              <img
                src="/assets/images/update-10-02-2023/shapes/main-slider-four-shape-1.png"
                alt=""
              />
            </div>
            <div className="main-slider-four__shape-2 float-bob-x">
              <img
                src="/assets/images/update-10-02-2023/shapes/main-slider-four-shape-2.png"
                alt=""
              />
            </div>
            <div className="main-slider-four__shadow"></div>

            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="main-slider-four__content">
                    <p className="main-slider-four__sub-title">
                      Protecting Your Health,
                    </p>
                    <h2 className="main-slider-four__title">
                      Securing <br /> Your Future <br /> & family life
                      <span>.</span>
                    </h2>
                    <div className="main-slider-four__btn-box">
                      <a
                        href="#insurance"
                        className="thm-btn-two main-slider-four__btn"
                      >
                        Discover More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="swiper-slide">
            <div className="main-slider-four__img">
              <img
                src="/assets/images/update-10-02-2023/resources/main-slider-four-img-1.jpg"
                alt=""
              />
            </div>
            {/* <!-- /.image-layer --> */}

            <div className="main-slider-four__shape-1 float-bob-y">
              <img
                src="/assets/images/update-10-02-2023/shapes/main-slider-four-shape-1.png"
                alt=""
              />
            </div>
            <div className="main-slider-four__shape-2 float-bob-x">
              <img
                src="/assets/images/update-10-02-2023/shapes/main-slider-four-shape-2.png"
                alt=""
              />
            </div>
            <div className="main-slider-four__shadow"></div>

            <div className="container">
              <div className="row">
                <div className="col-xl-12">
                  <div className="main-slider-four__content">
                    <p className="main-slider-four__sub-title">
                      Your Path to Wellness Starts Here
                    </p>
                    <h2 className="main-slider-four__title">
                      Health Insurance <br /> for Every Stage <br /> of Life
                      <span>.</span>
                    </h2>
                    <div className="main-slider-four__btn-box">
                      <a
                        href="#insurance"
                        className="thm-btn-two main-slider-four__btn"
                      >
                        Discover More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- If we need navigation buttons --> */}
        <div className="main-slider-four__nav">
          <div
            className="swiper-button-prev"
            id="main-slider__swiper-button-next"
          >
            <i className="icon-right-arrow"></i>
          </div>
          <div
            className="swiper-button-next"
            id="main-slider__swiper-button-prev"
          >
            <i className="icon-right-arrow1"></i>
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainSlider;

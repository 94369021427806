import React from "react";

const About = () => {
  return (
    <section class="about-five" id="about">
      <div className="container">
        <div className="row">
          <div className="col-xl-6">
            <div className="about-five__left">
              <div
                className="about-five__img-box wow slideInLeft"
                data-wow-delay="100ms"
                data-wow-duration="2500ms"
              >
                <div className="about-five__img">
                  <img
                    height={"550px"}
                    src="assets/images/update-10-02-2023/resources/about-us-two.jpg"
                    alt=""
                  />
                </div>
                <div className="about-five__img-two">
                  <img
                    src="assets/images/update-10-02-2023/resources/about-us.jpg"
                    alt=""
                  />
                </div>
                <div className="about-five__call">
                  <div className="about-five__call-icon">
                    <i className="fas fa-phone"></i>
                  </div>
                  <div className="about-five__call-content">
                    <p className="about-five__call-sub-title">
                      Call to Experts
                    </p>
                    <h4 class="about-five__call-number">
                      <a href="tel:9200368090">+ 234 810 373 4621</a>
                    </h4>
                  </div>
                </div>
                <div className="about-five__shape-1 zoominout"></div>
                <div className="about-five__arrow float-bob-x">
                  <img
                    src="assets/images/update-10-02-2023/shapes/naija pix 2.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="about-five__right">
              <div className="section-title-two text-left">
                <div className="section-title-two__sub-title-box">
                  <p className="section-title-two__sub-title">
                    {" "}
                    <span className="section-title-two__slash-one">
                      //
                    </span>{" "}
                    About company
                    <span className="section-title-two__slash-two">\\</span>
                  </p>
                </div>
                <h2 className="section-title-two__title">
                  We provide the best insurance policy
                </h2>
              </div>
              <p className="about-five__text">
                NextGen Health Tech is an innovative Healthtech start up
                headquartered in Abuja Nigeria. The company was started due to
                the fact that less than 10% of Nigerians currently have any form
                of health insurance coverage, the lowest in the world. Our
                solution is an affordable private health insurance package for
                the neglected 90% of the population.
              </p>
              <div className="about-five__points-and-counter-box">
                <div className="about-five__points-box">
                  <ul className="about-five__points list-unstyled">
                    <li>
                      <div className="icon">
                        <i className="fa fa-check"></i>
                      </div>
                      <div className="text">
                        <h4> We are affordable, starting at #1,000 monthly </h4>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-check"></i>
                      </div>
                      <div className="text">
                        <h4>We are available in every corner of Nigeria</h4>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-check"></i>
                      </div>
                      <div className="text">
                        <h4>We have a wide range of hospitals to chose</h4>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-check"></i>
                      </div>
                      <div className="text">
                        <h4>We respond to your issues within 10 minutes!</h4>
                      </div>
                    </li>
                    <li>
                      <div className="icon">
                        <i className="fa fa-check"></i>
                      </div>
                      <div className="text">
                        <h4>If its covered, its covered. No stories!</h4>
                      </div>
                    </li>
                  </ul>
                  <div className="about-five__btn-box">
                    <a
                      href="#insurance"
                      className="thm-btn-two about-five__btn"
                    >
                      Discover More
                    </a>
                  </div>
                </div>
                {/* <div className="about-five__counter-box">
                  <ul className="list-unstyled about-five__counter-list">
                    <li>
                      <div className="about-five__counter-single">
                        <div className="about-five__count-box count-box">
                          <h3
                            className="count-text"
                            data-stop="100"
                            data-speed="1500"
                          >
                            00
                          </h3>
                          <span className="about-five__count-percent">%</span>
                        </div>
                        <p className="about-five__counter-text">Success rate</p>
                      </div>
                    </li>
                    <li>
                      <div className="about-five__counter-single">
                        <div className="about-five__count-box count-box">
                          <h3
                            className="count-text"
                            data-stop="9860"
                            data-speed="1500"
                          >
                            00
                          </h3>
                        </div>
                        <p className="about-five__counter-text">
                          Satisfied clients
                        </p>
                      </div>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

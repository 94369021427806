import { useState, useEffect } from "react";
import Footer from "./common/footer";
import NavBar from "./common/nav";
import MobileNav from "./common/nav/mobile-nav";
import About from "./components/about";
import Services from "./components/services";
import MainSlider from "./components/slider";
import FeatureFive from "./components/slider/features";
import Team from "./components/team";
import WaitList from "./components/wait-list";
import WhyChooseUs from "./components/why-choose-us";
import { PageWrapperContainer } from "./gen-styles/app-styles";

function App() {
  const [addMobileDisplayClass, setAddMobileDisplayClass] = useState("");
  const [stickyClass, setStickyClass] = useState("relative");
  const handleMobileNavDisplay = () => {
    setAddMobileDisplayClass("expanded");
  };
  const handleHideMobileNavDisplay = () => {
    setAddMobileDisplayClass("");
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);

    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 500
        ? setStickyClass("sticky")
        : setStickyClass("relative");
    }
  };
  return (
    <>
      <PageWrapperContainer className="page-wrapper">
        <NavBar
          sticky={stickyClass}
          handleMobileNavDisplay={handleMobileNavDisplay}
        />
        <div className="stricky-header stricked-menu main-menu main-menu-four">
          <div className="sticky-header__content"></div>
          {/* <!-- /.sticky-header__content --> */}
        </div>
        <MainSlider />
        <FeatureFive />
        <About />
        <Services />
        <WhyChooseUs />
        {/* <Team /> */}
        <WaitList />
        {/* <h1>jkdksdkdkkjk</h1> */}
        <Footer />
      </PageWrapperContainer>

      <MobileNav
        expandedClass={addMobileDisplayClass}
        closeMobileNav={handleHideMobileNavDisplay}
      />
    </>
  );
}

export default App;

import React, { useState } from "react";

const WaitList = () => {
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    fullName: "",
    email: "",
    password: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateField(name, value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if there are any errors
    const hasErrors = Object.values(formErrors).some((error) => error !== "");

    if (!hasErrors) {
      // Form data is valid, prepare it for submission
      const dataToSubmit = {
        fullName: formValues.fullName,
        email: formValues.email,
        password: formValues.password,
      };

      setIsSubmitting(true);
      try {
        const response = await fetch(
          "https://tech-gen-services.onrender.com/sign-up",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(dataToSubmit),
          }
        );
        const returnedResponse = await response.json();
        if (returnedResponse.message === "success!!!, an email has been sent") {
          // Form submission was successful
          console.log("Form submission successful");
          setSuccessMsg("Form submission successful");
          setFormValues({
            fullName: "",
            email: "",
            password: "",
          });
        } else {
          // Form submission failed, handle the error
          console.error("Form submission failed");
          const responseData = await response.json();
          setError(responseData.message);
        }
      } catch (error) {
        console.error("An error occurred while submitting the form:", error);
        setError("An error occurred while submitting the form:", error);
      } finally {
        setIsSubmitting(false);
        setError("");
      }
    } else {
      console.log("Form data contains errors. Please correct them.");
      setError("Form data contains errors. Please correct them.");
    }
  };

  const formerhandleSubmit = async (e) => {
    e.preventDefault();
    if (formValues.fullName.strim <= 5 && isValidEmail(formValues.email)) {
      setIsSubmitting(true);
      setError("");

      try {
        const response = await fetch(
          "https://tech-gen-services.onrender.com/sign-up",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formValues),
          }
        );
        const returnedResponse = await response.json();
        console.log(returnedResponse, "from response");
        if (returnedResponse.message === "success!!!, an email has been sent") {
          // Signup successful
          console.log("Signup successful");
          setSuccessMsg("Signup successful");
        } else {
          // Signup failed, handle the error
          const responseData = await response.json();
          setError(responseData.message); // You may receive an error message from the API
        }
      } catch (error) {
        console.error("An error occurred:", error);
        console.log("Error:", error);
        setError("An error occurred while signing up.");
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // Invalid email, show an error message
      setError("Invalid email address");
    }
  };

  const isValidEmail = (value) => {
    // Use a simple regex pattern to validate the email
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return emailPattern.test(value);
  };

  const validateField = (fieldName, value) => {
    let errorMessage = "";

    switch (fieldName) {
      case "fullName":
        if (value.trim() === "") {
          errorMessage = "Full name is required";
        }
        break;
      case "email":
        const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        if (!emailPattern.test(value)) {
          errorMessage = "Invalid email address";
        }
        break;
      case "password":
        if (value.length < 8) {
          errorMessage = "Password must be at least 8 characters long";
        }
        break;
      default:
        break;
    }

    setFormErrors({
      ...formErrors,
      [fieldName]: errorMessage,
    });
  };

  console.log("sklslkdkd", formValues);
  return (
    <section className="get-insurance-three" id="insurance">
      <div
        className="get-insurance-three__bg"
        style={{
          backgroundImage:
            "url(/assets/images/update-10-02-2023/backgrounds/get-insurance-three-bg.png)",
        }}
      ></div>
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6">
            <div className="get-insurance-three__left">
              <div className="get-insurance-three__img">
                <img
                  src="assets/images/update-10-02-2023/services/istockphoto-1463320013-612x612.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6">
            <div className="get-insurance-three__right">
              <div className="section-title-two text-left">
                <div className="section-title-two__sub-title-box">
                  <p className="section-title-two__sub-title">
                    <span className="section-title-two__slash-one">//</span>
                    get notified when we are up
                    <span className="section-title-two__slash-two">\\</span>
                  </p>
                </div>
                <h2 className="section-title-two__title">Join our Wait List</h2>
              </div>
              <div className="get-insurance-three__main-tab-box tabs-box">
                <div className="tabs-content">
                  {/* <!--tab--> */}
                  <div class="tab active-tab" id="car-insurance">
                    <div className="get-insurance-three__content">
                      <form className="get-insurance-three__form">
                        <div className="get-insurance-three__content-box">
                          <p style={{ textAlign: "center", color: "red" }}>
                            {error}
                          </p>
                          <p
                            style={{
                              textAlign: "center",
                              color: "#51F530",
                              fontWeight: "bolder",
                              fontSize: "24px",
                            }}
                          >
                            {successMsg}
                          </p>
                          <div className="get-insurance-three__input-box">
                            <input
                              type="text"
                              placeholder="Full name"
                              name="fullName"
                              value={formValues.fullName}
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="get-insurance-three__input-box">
                            <input
                              type="email"
                              placeholder="Email address"
                              value={formValues.email}
                              name="email"
                              onChange={handleInputChange}
                            />
                          </div>
                          <div className="get-insurance-three__input-box">
                            <input
                              type="password"
                              placeholder="Password"
                              value={formValues.password}
                              name="password"
                              onChange={handleInputChange}
                            />
                          </div>
                        </div>
                        <div className="get-insurance-three__content-bottom">
                          <button
                            // type="submit"
                            onClick={handleSubmit}
                            className="thm-btn-two get-insurance-three__btn"
                          >
                            {isSubmitting ? "Signing up..." : "Join Wait List"}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WaitList;

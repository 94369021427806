import React from "react";

const Services = () => {
  return (
    <section className="services-four" id="services">
      <div
        className="services-four__bg"
        style={{
          backgroundImage:
            "url(/assets/images/update-10-02-2023/backgrounds/services-four-bg.jpg)",
        }}
      ></div>
      <div className="container">
        <div className="section-title-two text-center">
          <div className="section-title-two__sub-title-box">
            <p className="section-title-two__sub-title">
              {" "}
              <span class="section-title-two__slash-one">//</span>
              our top services
              <span className="section-title-two__slash-two">\\</span>
            </p>
          </div>
          <h2 className="section-title-two__title">
            We're addressing health insurance <br /> comprehensively.
          </h2>
        </div>
        <div
          className="owl-carousel owl-theme thm-owl__carousel services-four__carousel"
          data-owl-options='{
                    "loop": true,
                    "autoPlay": true,
                    "margin": 20,
                    "nav": false,
                    "dots": true,
                    "smartSpeed": 500,
                    "autoplayTimeout": 10000,
                    "navText": ["<span className=\"fa fa-angle-left\"></span>","<span class=\"fa fa-angle-right\"></span>"],
                    "responsive": {
                        "0": {
                            "items": 1
                        },
                        "768": {
                            "items": 3
                        },
                        "992": {
                            "items": 4
                        },
                        "1200": {
                            "items": 5
                        }
                    }
                }'
        >
          {/* <!--Services Four Single Start--> */}
          <div className="item">
            <div className="services-four__single">
              <div className="services-four__img-box">
                <div className="services-four__img">
                  <img
                    src="/assets/images/update-10-02-2023/services/naija pix 4.jpg"
                    alt=""
                  />
                </div>
                <div className="services-four__icon">
                  {/* <span className="icon-drive"></span> */}
                </div>
              </div>
              <div className="services-four__content-box">
                <div className="services-four__content">
                  <h4 class="services-four__title">
                    <a href="car-insurance.html">Private Health Insurance</a>
                  </h4>
                  <p className="services-four__text">
                    Supplemental coverage <br />
                    for medical expenses.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Services Four Single End--> */}
          {/* <!--Services Four Single Start--> */}
          <div className="item">
            <div className="services-four__single">
              <div className="services-four__img-box">
                <div className="services-four__img">
                  <img
                    src="/assets/images/update-10-02-2023/services/730x415.jpg"
                    alt=""
                  />
                </div>
                <div className="services-four__icon">
                  <span className="icon-family"></span>
                </div>
              </div>
              <div className="services-four__content-box">
                <div className="services-four__content">
                  <h4 class="services-four__title">
                    <a href="#">Group Health Insurance</a>
                  </h4>
                  <p className="services-four__text">
                    Enhanced coverage <br />
                    for employee healthcare.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Services Four Single End--> */}
          {/* <!--Services Four Single Start--> */}
          <div className="item">
            <div className="services-four__single">
              <div className="services-four__img-box">
                <div className="services-four__img">
                  <img
                    src="/assets/images/update-10-02-2023/services/naija pix 3.jpg"
                    alt=""
                  />
                </div>
                <div className="services-four__icon">
                  <span className="icon-home"></span>
                </div>
              </div>
              <div className="services-four__content-box">
                <div className="services-four__content">
                  <h4 class="services-four__title">
                    <a href="home-insurance.html">
                      Association Health Insurance
                    </a>
                  </h4>
                  <p className="services-four__text">
                    Group coverage through <br />
                    professional associations.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Services Four Single End--> */}
          {/* <!--Services Four Single Start--> */}
          <div className="item">
            <div className="services-four__single">
              <div className="services-four__img-box">
                <div className="services-four__img">
                  <img
                    src="/assets/images/update-10-02-2023/services/istockphoto-1081379026-612x612.jpg"
                    alt=""
                  />
                </div>
                <div className="services-four__icon">
                  <span className="icon-heart-beat"></span>
                </div>
              </div>
              <div className="services-four__content-box">
                <div className="services-four__content">
                  <h4 class="services-four__title">
                    <a href="#">Emergency Care Insurance </a>
                  </h4>
                  <p className="services-four__text">
                    Urgent medical coverage <br />
                    for emergencies.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Services Four Single End--> */}
          {/* <!--Services Four Single Start--> */}
          <div className="item">
            <div className="services-four__single">
              <div className="services-four__img-box">
                <div className="services-four__img">
                  <img
                    src="/assets/images/update-10-02-2023/services/istockphoto-1355666522-612x612.jpg"
                    alt=""
                  />
                </div>
                <div className="services-four__icon">
                  <span className="icon-briefcase"></span>
                </div>
              </div>
              <div className="services-four__content-box">
                <div className="services-four__content">
                  <h4 class="services-four__title">
                    <a href="business-insurance.html">Telemedicine </a>
                  </h4>
                  <p className="services-four__text">
                    Remote healthcare consultations <br />
                    and services.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Services Four Single End--> */}
          {/* <!--Services Four Single Start--> */}
          <div className="item">
            <div className="services-four__single">
              <div className="services-four__img-box">
                <div className="services-four__img">
                  <img
                    src="/assets/images/update-10-02-2023/services/istockphoto-1489961029-612x612.jpg"
                    alt=""
                  />
                </div>
                <div className="services-four__icon">
                  <span className="icon-drive"></span>
                </div>
              </div>
              <div className="services-four__content-box">
                <div className="services-four__content">
                  <h4 class="services-four__title">
                    <a href="car-insurance.html">Hospital Software</a>
                  </h4>
                  <p className="services-four__text">
                    Medical facility management <br />
                    software solution.
                  </p>
                </div>
              </div>
            </div>
          </div>
          {/* <!--Services Four Single End--> */}
        </div>
      </div>
    </section>
  );
};

export default Services;

import React from "react";

const Footer = () => {
  return (
    <footer className="site-footer-two">
      <div className="container">
        <div className="site-footer-two__top">
          <div className="row">
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="100ms"
            >
              <div className="footer-widget__column footer-widget-two__about">
                <div className="footer-widget-two__logo">
                  <a href="#index">
                    <img
                      src="assets/images/update-10-02-2023/resources/c491f5b1-44a3-4d51-b07a-ab02bb2bda1b-removebg-preview.png"
                      alt=""
                    />
                  </a>
                </div>
                <div className="footer-widget-two__about-text-box">
                  <p
                    className="footer-widget-two__about-text"
                    style={{ color: "white" }}
                  >
                    Our health insurance company prioritizes your well-being by
                    offering comprehensive coverage and exceptional customer
                    service.
                  </p>
                </div>
                <div className="site-footer-two__social">
                  <a href="https://twitter.com/NextGenHealthNG">
                    <i className="fab fa-twitter"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-facebook"></i>
                  </a>
                  <a href="https://www.linkedin.com/company/nextgen-health-tech-ltd/">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="https://instagram.com/nextgenhealthtech?igshid=NzZlODBkYWE4Ng==">
                    <i className="fab fa-instagram"></i>
                  </a>
                </div>
              </div>
            </div>
            <div
              className="col-xl-2 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="200ms"
            >
              <div className="footer-widget__column footer-widget-two__links clearfix">
                <h3 className="footer-widget-two__title">Links</h3>
                <ul className="footer-widget-two__links-list list-unstyled clearfix">
                  <li>
                    <a href="#about">About</a>
                  </li>
                  <li>
                    <a href="#insurance">Join</a>
                  </li>
                  {/* <li>
                    <a href="portfolio.html">Latest Portfolio</a>
                  </li> */}
                  {/* <li>
                    <a href="faq.html">Our Faqs</a>
                  </li> */}
                  {/* <li>
                    <a href="contact.html">Get in Touch</a>
                  </li> */}
                </ul>
              </div>
            </div>
            <div
              className="col-xl-4 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="300ms"
            >
              <div className="footer-widget__column footer-widget-two__latest-news clearfix">
                <h3 className="footer-widget-two__title">Upcoming Blogs</h3>
                <ul className="footer-widget-two__latest-news-list list-unstyled clearfix">
                  <li>
                    <div className="footer-widget-two__latest-news-img">
                      <img
                        src="assets/images/update-10-02-2023/resources/footer-widget-two-latest-news-img-1-1.jpg"
                        alt=""
                      />
                    </div>
                    <div className="footer-widget-two__latest-news-content">
                      <p className="footer-widget-two__latest-news-date">
                        20 Nov, 2023
                      </p>
                      <h4 className="footer-widget-two__latest-news-title">
                        <a href="#">
                          Our Insurance Strategy <br /> for Large Business
                        </a>
                      </h4>
                    </div>
                  </li>
                  {/* <li>
                    <div className="footer-widget-two__latest-news-img">
                      <img
                        src="assets/images/update-10-02-2023/resources/footer-widget-two-latest-news-img-1-2.jpg"
                        alt=""
                      />
                    </div>
                    <div className="footer-widget-two__latest-news-content">
                      <p className="footer-widget-two__latest-news-date">
                        20 Jan, 2023
                      </p>
                      <h4 className="footer-widget-two__latest-news-title">
                        <a href="news-details.html">
                          Lorem ipsum dolor <br /> sit amet
                        </a>
                      </h4>
                    </div>
                  </li> */}
                </ul>
              </div>
            </div>
            <div
              className="col-xl-3 col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="400ms"
            >
              <div className="footer-widget__column footer-widget-two__contact clearfix">
                <h3 className="footer-widget-two__title">Contact</h3>
                <p
                  className="footer-widget-two__contact-text"
                  style={{ color: "white" }}
                >
                  Reach us on all our social media platforms to get quick
                  response. <br />
                </p>
                <ul className="footer-widget-two__contact-list list-unstyled clearfix">
                  <li>
                    <div className="icon">
                      <span className="fas fa-envelope"></span>
                    </div>
                    <div className="text">
                      <p>
                        <a href="mailto: nextgenhealthtechltd@gmail.com ">
                          nextgenhealthtechltd@gmail.com
                        </a>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="icon">
                      <span className="fas fa-phone-alt"></span>
                    </div>
                    <div className="text">
                      <p>
                        <a href="tel:9200368090">+ 234 810 373 4621</a>
                      </p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="site-footer-two__newsletter">
          <div className="site-footer-two__newsletter-left">
            <div className="site-footer-two__newsletter-icon">
              <span className="icon-email"></span>
            </div>
            <p className="site-footer-two__newsletter-content">
              Get Daily Insurance News
            </p>
          </div>
          <div className="site-footer-two__newsletter-right">
            <form className="site-footer-two__newsletter-form">
              <div className="site-footer-two__newsletter-input-box">
                <input
                  type="email"
                  placeHolder="Enter Your Email"
                  name="email"
                />
                <button type="submit" className="newsletter__btn thm-btn-two">
                  Subscribe Now
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="site-footer-two__bottom">
        <div className="container">
          <div className="row">
            <div className="col-xl-12">
              <div className="site-footer-two__bottom-inner">
                <p className="site-footer-two__bottom-text">
                  © All Copyright 2023 by <a href="#">Next Gen</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

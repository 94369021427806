import React from "react";

const FeatureFive = () => {
  return (
    <section className="feature-five">
      <div className="container">
        <ul className="list-unstyled feature-five__list">
          <li>
            <div className="feature-five__single">
              <div className="feature-five__icon">
                <span className="insur-three-icon-insurance"></span>
              </div>
              <h3 class="feature-five__title">
                <a href="#">₦1,000 monthly</a>
              </h3>
              <p className="feature-five__text">
                Our health insurance starts at just ₦1,000 (one thousand naira)
                per month, ensuring affordability.
              </p>
            </div>
          </li>
          <li>
            <div className="feature-five__single">
              <div className="feature-five__icon">
                <span className="insur-three-icon-cashback"></span>
              </div>
              <h3 class="feature-five__title">
                <a href="#">Multiple hospital network</a>
              </h3>
              <p className="feature-five__text">
                We offer a diverse hospital network for your selection and
                convenience.
              </p>
            </div>
          </li>
          <li>
            <div className="feature-five__single">
              <div className="feature-five__icon">
                <span className="insur-three-icon-house"></span>
              </div>
              <h3 class="feature-five__title">
                <a href="#">Fast & reliable</a>
              </h3>
              <p className="feature-five__text">
                Expect our prompt response within just ten minutes to address
                your concerns!
              </p>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default FeatureFive;

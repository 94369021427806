import React, { useState } from "react";
const MobileNav = ({ expandedClass, closeMobileNav }) => {
  return (
    <div className={`mobile-nav__wrapper ${expandedClass}`}>
      <div className="mobile-nav__overlay mobile-nav__toggler"></div>
      {/* <!-- /.mobile-nav__overlay --> */}
      <div className="mobile-nav__content mobile-nav__content--two">
        <span
          className="mobile-nav__close mobile-nav__toggler"
          onClick={closeMobileNav}
        >
          <i className="fa fa-times"></i>
        </span>

        <div className="logo-box">
          <a href="index.html" aria-label="logo image">
            <img
              src="assets/images/update-10-02-2023/resources/c491f5b1-44a3-4d51-b07a-ab02bb2bda1b-removebg-preview.png"
              width="143"
              alt=""
            />
          </a>
        </div>
        {/* <!-- /.logo-box --> */}
        <div className="mobile-nav__container">
          <ul className="main-menu__list one-page-scroll-menu">
            <li className="dropdown current scrollToLink">
              <a onClick={closeMobileNav} href="#home">
                Home
              </a>
            </li>
            <li className="scrollToLink">
              <a onClick={closeMobileNav} href="#about">
                About
              </a>
            </li>
            <li className="scrollToLink">
              <a onClick={closeMobileNav} href="#services">
                Services
              </a>
            </li>
            {/* <li className="scrollToLink">
              <a onClick={closeMobileNav} href="#team">
                Team
              </a>
            </li> */}
            <li className="scrollToLink">
              <a onClick={closeMobileNav} href="#insurance">
                Join wait list
              </a>
            </li>
            {/* <li className="scrollToLink">
              <a href="#news">News</a>
            </li> */}
          </ul>
        </div>
        {/* <!-- /.mobile-nav__container --> */}

        <ul className="mobile-nav__contact mobile-nav__contact--two list-unstyled">
          <li>
            <i className="fa fa-envelope"></i>
            <a href="mailto:needhelp@packageName__.com">needhelp@nextgen.com</a>
          </li>
          <li>
            <i className="fa fa-phone-alt"></i>
            <a href="tel:+ 234 810 373 4621">+ 234 810 373 4621</a>
          </li>
        </ul>
        {/* <!-- /.mobile-nav__contact --> */}
        <div className="mobile-nav__top">
          <div className="mobile-nav__social">
            <a
              href="https://twitter.com/NextGenHealthNG"
              className="fab fa-twitter"
            ></a>
            <a href="#" className="fab fa-facebook-square"></a>
            <a
              href="https://www.linkedin.com/company/nextgen-health-tech-ltd/"
              className="fab fa-linkedin-in"
            ></a>
            <a
              href="https://instagram.com/nextgenhealthtech?igshid=NzZlODBkYWE4Ng=="
              className="fab fa-instagram"
            ></a>
          </div>
          {/* <!-- /.mobile-nav__social --> */}
        </div>
        {/* <!-- /.mobile-nav__top --> */}
      </div>
      {/* <!-- /.mobile-nav__content --> */}
    </div>
  );
};

export default MobileNav;

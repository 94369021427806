import React from "react";

const WhyChooseUs = () => {
  return (
    <section className="why-choose-three">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-7">
            <div className="why-choose-three__left">
              <div className="why-choose-three__shape-4 float-bob-x">
                <img
                  src="assets/images/update-10-02-2023/shapes/why-choose-three-shape-4.png"
                  alt=""
                />
              </div>
              <div className="section-title-two text-left">
                <div className="section-title-two__sub-title-box">
                  <p className="section-title-two__sub-title">
                    {" "}
                    <span className="section-title-two__slash-one">//</span>
                    few reasons
                    <span className="section-title-two__slash-two">\\</span>
                  </p>
                </div>
                <h2 className="section-title-two__title">
                  Why you should choose our insurance policy
                </h2>
              </div>
              <p class="why-choose-three__text-1">
                A few reasons to select our health insurance.
              </p>
              <div className="why-choose-three__points-box">
                <ul className="list-unstyled why-choose-three__points">
                  <li>
                    <div className="why-choose-three__points-single">
                      <div className="why-choose-three__points-icon-box">
                        <div className="why-choose-three__points-icon">
                          <span className="icon-tick"></span>
                        </div>
                        <p className="why-choose-three__points-icon-text">
                          ₦1,000
                          <br /> monthly
                        </p>
                      </div>
                      <div className="why-choose-three__points-content-box">
                        <p
                          className="why-choose-three__points-content"
                          style={{ color: "white" }}
                        >
                          We are affordable, starting at ₦1,000 monthly, and
                          available in every corner of Nigeria
                        </p>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="why-choose-three__points-single">
                      <div className="why-choose-three__points-icon-box">
                        <div className="why-choose-three__points-icon">
                          <span className="icon-tick"></span>
                        </div>
                        <p className="why-choose-three__points-icon-text">
                          10 minutes <br /> response
                        </p>
                      </div>
                      <div className="why-choose-three__points-content-box">
                        <p
                          className="why-choose-three__points-content"
                          style={{ color: "white" }}
                        >
                          We respond to your issues within 10 minutes!, We have
                          a wide range of hospitals to chose from .
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div className="why-choose-three__btn-box">
                <a href="#" className="thm-btn-two why-choose-three__btn">
                  Discover More
                </a>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-5">
            <div className="why-choose-three__right">
              <div className="why-choose-three-shape-1"></div>
              <div className="why-choose-three-shape-2"></div>
              <div className="why-choose-three-shape-3"></div>
              <div className="why-choose-three__img">
                <img
                  src="assets/images/update-10-02-2023/resources/why-choose-us.jpg"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;
